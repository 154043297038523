import React from 'react'
import './styles.scss'
import { skills } from '../../sections/skills/data'

const SkillsList = () => {
    return (
        <div className='skillsListWrapper'>
            {
                skills?.map(skill => (
                    <div key={skill.name} className='skillContainer' style={{ backgroundColor: `${skill?.background}` }} data-aos="flip-up" data-aos-duration="500">
                        <div className='skillImageContainer'>
                            <img src={skill.icon} alt={skill.name} width={skill.iconW} height={skill.iconH} />
                        </div>
                        <p className='skillNameContainer'>{skill.name}</p>
                    </div>
                ))
            }
        </div>
    )
}

export default SkillsList