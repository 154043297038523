import React from 'react'
import './styles.scss'
import Title from '../../components/title'
import { Col, Container, Row } from 'react-bootstrap'
import ContactUsFields from '../../components/contactUsFieds'
import contactus from '../../assets/images/Programmer-rafiki.png'

const ContactUs = () => {
    return (
        <div className='contactUsWrapper'>
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Title title="Contact Us" />
                    </Col>
                </Row>
                <div className='contactUsContainer'>
                    <Row>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                            <div className='contactUsImageContainer'>
                                <img src={contactus} alt="contact us" />
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                            <ContactUsFields />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default ContactUs