import html from '../../assets/images/html.png'
import react from '../../assets/images/react.png'
import css3 from '../../assets/images/css3.png'
import js from '../../assets/images/js.png'
import php from '../../assets/images/php.png'
import netlify from '../../assets/images/netlify.png'
import mysql from '../../assets/images/mysql.png'
import figma from '../../assets/images/figma.png'
import github from '../../assets/images/github.png'
import git from '../../assets/images/git.png'
import gitlab from '../../assets/images/gitlab.png'
import java from '../../assets/images/java.png'
import digitalOcean from '../../assets/images/do.png'


export const skills = [
    {
        id: '1',
        name: 'HTML5',
        icon: html,
        background: 'rgba(227, 79, 38, 0.4)',
        iconW: 70,
        iconH: 70,
    },
    {
        id: '2',
        name: 'CSS3',
        icon: css3,
        background: 'rgba(38, 77, 228, 0.4)',
        iconW: 70,
        iconH: 70,
    },
    {
        id: '3',
        name: 'javascript',
        icon: js,
        background: 'rgba(247, 223, 30, 0.4)',
        iconW: 70,
        iconH: 70,
    },
    {
        id: '4',
        name: 'react',
        icon: react,
        background: 'rgba(97, 218, 251, 0.4)',
        iconW: 80,
        iconH: 70,
    },
    {
        id: '5',
        name: 'php',
        icon: php,
        background: 'rgba(119, 123, 179, 0.4)',
        iconW: 70,
        iconH: 45,
    },
    {
        id: '6',
        name: 'netlify',
        icon: netlify,
        background: 'rgba(56, 173, 188, 0.4)',
        iconW: 70,
        iconH: 70,
    },
    {
        id: '7',
        name: 'mysql',
        icon: mysql,
        background: 'rgba(224, 111, 20, 0.4)',
        iconW: 70,
        iconH: 40,
    },
    {
        id: '9',
        name: 'figma',
        icon: figma,
        background: 'rgba(10, 207, 131, 0.4)',
        iconW: 45,
        iconH: 60,
    },
    {
        id: '10',
        name: 'github',
        icon: github,
        background: 'rgba(255, 255, 255, 0.4)',
        iconW: 70,
        iconH: 70,
    },
    {
        id: '11',
        name: 'git',
        icon: git,
        background: 'rgba(240, 80, 51, 0.4)',
        iconW: 70,
        iconH: 70,
    },
    {
        id: '12',
        name: 'gitlab',
        icon: gitlab,
        background: 'rgba(252, 164, 33, 0.4)',
        iconW: 70,
        iconH: 70,
    },
    {
        id: '13',
        name: 'java',
        icon: java,
        background: 'rgba(46, 109, 186, 0.4)',
        iconW: 55,
        iconH: 70,
    },
    {
        id: '14',
        name: 'Digital Ocean',
        icon: digitalOcean,
        background: 'rgba(0, 128, 255, 0.2)',
        iconW: 70,
        iconH: 70,
    },
]