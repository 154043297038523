import React from 'react'
import './styles.scss'
import { Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai'
import { projects } from '../../data';

const ProjectsList = ({ SetShowProjects }) => {
    return (
        <div className='projectsListWrapper'>
            <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className='projectsListCloseButtonContainer'>
                    <AiOutlineClose color='fff' size={30} onClick={() => SetShowProjects(false)} />
                </div>
            </Row>
            <Row>
                <div className='myswiperWrapper'>
                    {projects?.map((project, index) => (
                        <div key={index} className="mySwiperSlide">
                            <div className='mySwiperContainer'>
                                <div key={project.projectName} className='mySwiperWebsitesContainer'>
                                    <div className='mySwiperWebsitess'>
                                        {Object.keys(project.websites[0]).map((websiteKey) => (
                                            <div key={websiteKey} className='mySwiperWebsites'>
                                                {project.websites[0][websiteKey].map((website, websiteIndex) => (
                                                    <a key={websiteIndex} href={website.link} target="_blank" rel="noopener noreferrer">
                                                        <p>
                                                            {website?.name}
                                                        </p>
                                                    </a>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                    <p className='mySwiperWebsitesDescription'>{project.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Row>
        </div >
    )
}

export default ProjectsList