import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './styles.scss'
import { Link } from 'react-scroll'


function Navigation() {


    return (
        <Navbar expand="sm">
            <Container fluid >
                <Navbar.Brand href="/" >
                    <div className='logo'></div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll"
                    className='toggle'
                    style={{
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0
                    }} />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                    </Nav>
                    <Nav
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Link activeClass="active" to="aboutme" spy={true} smooth={false}>
                            <p className='aboutMe'>About me</p>
                        </Link>
                        <Link activeClass="active" to="projects" spy={true} smooth={false}>
                            <p className='projects'>Projects</p>
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;