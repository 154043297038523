import React from 'react'
import './styles.scss'

function Loading() {
    return (
        <div className='loadingWrapper'>
            <svg width="100" height="100" viewBox="0 0 534 329" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M218 18.8129C209 -5.18697 187.5 -4.68695 180 18.8129V227.813C179.607 236.495 177.632 241.556 172 250.813C163.006 265.184 159.589 269.528 155 273.813C141.008 286.06 132.131 289.031 115.5 291.313H93.5C80.5248 288.736 66.5 277.813 66 277.813C65.5 277.813 51.9613 265.799 47 256.813C43.8 253.213 41 245.313 40 241.813C32.0091 207.096 2 212.813 1 240.813L6.5 257.813C11.1697 269.01 14.5734 274.856 21.5 284.813C27.385 291.837 30.9653 295.581 38 301.813C46.4087 308.969 54.1517 313.464 59 315.813C69.759 321.238 74.8164 323.094 82 324.813C91.1529 326.862 95.1484 327.615 99 327.813H118C127.682 327.158 132.8 326.219 141 322.813L159 314.813C169.857 309.31 178.5 302.313 181.5 297.813C195.514 282.494 198.5 277.313 199 276.813C199.5 276.313 206.695 264.575 209.5 257.813C212.663 250.081 213.831 245.463 215 236.813L217.5 216.813L218 18.8129Z" fill="white" stroke="white" className="svg-elem-1"></path>
                <path d="M532.371 297.882C537.871 325.882 512.371 331.882 498.871 315.882L394.871 63.8824C393.447 60.7439 392.675 60.636 391.371 63.8824L301.871 283.882C300.152 287.589 301.191 288.181 304.871 287.882H412.871C435.211 297.471 432.871 318.382 412.871 324.882H267.371C252.516 317.074 248.871 307.382 257.871 290.882L370.871 13.8825C381.651 -1.91781 404.871 -4.61755 415.871 13.8825L532.371 297.882Z" fill="white" stroke="white" className="svg-elem-2"></path>
            </svg>
        </div>
    )
}

export default Loading