import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Title from '../../components/title';
import './styles.scss';
import SkillsList from '../../components/skillsList';

function Skills() {
    return (
        <div className="skillsWrapper">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Title title="Skills" />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <SkillsList />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Skills