import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Navigation from '../../components/navbar'
import './styles.scss'
import Buttons from '../../components/buttons'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie-files/scroll3.json'
import * as animationData2 from '../../assets/lottie-files/robot.json'
import useViewport from "../../hook/useViewport";

function HomePage() {
    const { width } = useViewport();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <div className='homePageWrapper'>
                <Container >
                    <Row lg={12} >
                        <Navigation />
                    </Row>
                    <Row >
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className='heroSectionWrapper'>
                                <div data-aos="fade-up" data-aos-duration="1000">
                                    <p className='welcomeTypography'>
                                        WELCOME,<br></br>
                                        I’m <span> Joseph Awad </span>
                                    </p>
                                    <div className='vlContainer'>
                                        <div className='vl'></div>
                                    </div>
                                </div>
                                <div className='serviceTypography' data-aos="fade-up" data-aos-duration="1500">
                                    <p>Front-End web developer </p>
                                </div>
                                {
                                    width < 992 ?
                                        <div className='quoteTypography' data-aos="fade-up" data-aos-duration="1500">
                                            <p>Let's start code together</p>
                                        </div>
                                        :
                                        null
                                }
                                <div data-aos="fade-up" data-aos-duration="2500">
                                    <Buttons />
                                </div>

                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className='robotLottieContainer'>
                                <Lottie options={defaultOptions1} width={500} height={500} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className='lottieFileContainer'>
                                <div className='lottieFile'>
                                    <Lottie options={defaultOptions} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default HomePage