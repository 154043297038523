import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ServiceCards from '../../components/sercviceCards'
import Title from '../../components/title'
import './styles.scss'

function Services() {
    return (
        <div className='servicesWrapper'>
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Title title="Services" />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className='servicesContainer'>
                            <ServiceCards />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Services