import React from 'react'
import './styles.scss'

function Title(props) {
    return (
        <div className='title' data-aos="flip-up" data-aos-duration="500">
            <p>{props.title}</p>
        </div>
    )
}

export default Title