import React from 'react'
import './styles.scss'
import { BsGithub, BsLinkedin } from 'react-icons/bs'
import { AiOutlineGitlab } from 'react-icons/ai'
import { FaXTwitter, FaThreads } from 'react-icons/fa6'

function SocialMedia({ contact }) {
    return (
        <>
            <div className='socialMediaContainer'>
                <a href="https://x.com/Joseph_aawad?t=z5_fRr_7UB-zCy0uVBCraQ&s=09" target="_blank" rel="noopener noreferrer">
                    <FaXTwitter size={contact ? 45 : 28} className={contact ? "contactSocialMediaIcons" : "socialMediaIcons"} />
                </a>
                <a href="https://github.com/JosephAwadd" target="_blank" rel="noopener noreferrer">
                    <BsGithub size={contact ? 45 : 28} className={contact ? "contactSocialMediaIcons" : "socialMediaIcons"} />
                </a>
                <a href="https://gitlab.com/JosephAwad" target="_blank" rel="noopener noreferrer">
                    <AiOutlineGitlab size={contact ? 45 : 28} className={contact ? "contactSocialMediaIcons" : "socialMediaIcons"} />
                </a>
                <a href="https://www.linkedin.com/in/joseph-awad-1b1388238 " target="_blank" rel="noopener noreferrer">
                    <BsLinkedin size={contact ? 45 : 28} className={contact ? "contactSocialMediaIcons" : "socialMediaIcons"} />
                </a>
                <a href="https://www.threads.net/@josephawad03" target="_blank" rel="noopener noreferrer">
                    <FaThreads size={contact ? 45 : 28} className={contact ? "contactSocialMediaIcons" : "socialMediaIcons"} />
                </a>
            </div>
        </>
    )
}
export default SocialMedia