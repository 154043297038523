export const projects = [
    {
        projectName: 'Project1',
        description: 'This group of websites is designed for a comprehensive online experience. Each website within this group offers a unique landing page with engaging content.Additionally, users can access both Sign In and Sign Up features to create accounts,personalize their profiles, and access the content.',
        websites: [
            {
                website1: [
                    {
                        link: "https://www.winabet247.com",
                        name: "Winabet247",
                    }
                ],
                website2: [
                    {
                        link: "https://www.elitepunters.com/",
                        name: "Elitepunters",
                    }
                ],
                website3: [
                    {
                        link: "https://www.mysmartbookie.com/",
                        name: "MysmartBookie",
                    }
                ],
                website4: [
                    {
                        link: "https://www.cashabet365.com/",
                        name: "Cashabet365",
                    }
                ],
                wesbite5: [
                    {
                        link: "https://www.punterwinner.com/",
                        name: "Punterwinner",
                    }
                ]
            }
        ],
    },
    {
        projectName: 'Project2',
        description: 'Explore this collection of cryptocurrency trading websites for a comprehensive trading experience. Each website in this group provides a user-friendly platform to buy, sell, and trade a variety of cryptocurrencies. Users can access real-time market data, and personalize their trading profiles.',
        websites: [
            {
                website1: [
                    {
                        link: "https://www.cryptorus247.com",
                        name: "Cryptorus247",
                    }
                ],
                website2: [
                    {
                        link: "https://www.allroundcrypto.com/",
                        name: "Allroundcrypto",
                    }
                ],
                website3: [
                    {
                        link: "https://www.briocoin.com/",
                        name: "Briocoin",
                    }
                ],
                website4: [
                    {
                        link: "https://www.elitealtcoin.com/",
                        name: "Elitealtcoin",
                    }
                ],
                wesbite5: [
                    {
                        link: "https://www.vodocrypto.com/",
                        name: "Vodocrypto",
                    }
                ]
            }
        ],
    },
    {
        projectName: 'Project3',
        description: 'Discover a selection of crypto recovery websites dedicated to helping users regain control of their cryptocurrency assets. Each website in this group offers professional and reliable customer service to assist users in recovering lost or inaccessible crypto funds.',
        websites: [
            {
                website1: [
                    {
                        link: "https://www.thecryptoclearance.com",
                        name: "Thecryptoclearance",
                    }
                ],
                website2: [
                    {
                        link: "https://www.eliterecoveries.com/",
                        name: "Eliterecoveries",
                    }
                ],
                website3: [
                    {
                        link: "https://www.allcryptorefund.com/",
                        name: "Allcryptorefund",
                    }
                ],
                website4: [
                    {
                        link: "https://www.fintekrecovery.com/",
                        name: "Fintekrecovery",
                    }
                ],
                wesbite5: [
                    {
                        link: "https://www.cryptocashplus.com/",
                        name: "Cryptocashplus",
                    }
                ]
            }
        ],
    },
    {
        projectName: 'Project4',
        description: 'Explore this curated collection of sports betting websites. Each website in this group offers a wide range of sports events and betting options, allowing users to place bets on their favorite teams. With real-time odds, secure transactions, and user-friendly interfaces.',
        websites: [
            {
                website1: [
                    {
                        link: "https://www.crystalbookie.com",
                        name: "Crystalbookie",
                    }
                ],
                website2: [
                    {
                        link: "https://www.maxibet247.com/",
                        name: "Maxibet247",
                    }
                ],
                website3: [
                    {
                        link: "https://www.bookiepoint.com/",
                        name: "Bookiepoint",
                    }
                ],
                website4: [
                    {
                        link: "https://www.betearly365.com/",
                        name: "Betearly365",
                    }
                ],
                wesbite5: [
                    {
                        link: "https://www.securepunters.com/",
                        name: "Securepunters",
                    }
                ]
            }
        ],
    },
    {
        projectName: 'Project5',
        description: 'Explore multilingual hotel website. Discover the location, view images, and access services in your preferred language.',
        websites: [
            {
                website1: [
                    {
                        link: "https://www.apreciosahotel.com/",
                        name: "Apreciosahotel",
                    }
                ],

            }
        ],
    },
]