import React from 'react'
import profile from '../../assets/images/me.png'
import './styles.scss'

function ProfilePicture() {
    return (
        <div className='profilePictureWrapper' data-aos="fade-up" data-aos-duration="2000">
            <img alt="profile" src={profile} className="profilePicture" />
        </div>
    )
}

export default ProfilePicture