import React from 'react'
import './styles.scss'
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import SocialMedia from '../../components/socialmedia'


const ContactUsFields = () => {
    useEffect(() => emailjs.init("ROXbemcKWZMI71Q99"), []);
    const [message, setMessage] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            message: '',
        },
        onSubmit: async () => {
            const serviceId = "service_u5bsixu"
            const templateId = "template_663rwul"

            await emailjs.send(serviceId, templateId, {
                name: formik.values.name,
                message: formik.values.message,
            }).then(response => {
                setMessage(true);

                setTimeout(() => {
                    setMessage(false);
                }, 3000);
            });
        },
    });

    return (
        <div className='contactUsFieldsWrapper'>
            <div className='mb-5'>
                <SocialMedia contact={true} />
            </div>
            {
                message &&
                <div className='contactUsSuccessMessage'>
                    <p>Email sent successfully !!</p>
                </div>
            }
            <Form onSubmit={formik.handleSubmit} className='contactUsForm'>
                <Form.Label className='contactUsInputLabel'>Name</Form.Label>
                <Form.Control
                    className="mb-4 contactUsInput"
                    type="name"
                    placeholder="Enter your name"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                <Form.Label className='contactUsInputLabel'>Message</Form.Label>
                <Form.Control
                    className="mb-4 contactUsInput"
                    placeholder="Message"
                    as="textarea"
                    rows={5}
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                />
                <Button type="submit" className='contactUsSubmitButton'>
                    Send Email
                </Button>
            </Form>
        </div>
    )
}

export default ContactUsFields