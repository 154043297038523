import React from 'react'
import './styles.scss'

const Footer = () => {
    return (
        <div className='footerWrapper'>
            <p>© 2023-All Rights Reserved</p>
        </div>
    )
}

export default Footer