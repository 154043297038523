import { React } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './styles.scss'
import ProfilePicture from '../../components/profilePicture'
import Title from '../../components/title'
import SocialMedia from '../../components/socialmedia'

function AboutMe() {

    return (
        <div className='aboutMeWrapper' id="aboutme">
            <Container>
                <Row>
                    <Col lg={6} md={12}>
                        <div className='aboutmeContainer'>
                            <Title title="about me" />
                            <div className='aboutmeText'>
                                <div className='aboutmeTextParagraph'>
                                    <div data-aos="flip-up"
                                        data-aos-duration="500"
                                    >I am a student of computer science and a developer.</div>
                                    <div data-aos="flip-up"
                                        data-aos-duration="1000"
                                    >I have a passion for coding and technology and the ability to</div>
                                    <div data-aos="flip-up"
                                        data-aos-duration="1500"
                                    > use it to create innovative solutions. I have developed a</div>
                                    <div data-aos="flip-up"
                                        data-aos-duration="2000"
                                    >strong understanding of the various programming languages</div>
                                    <div data-aos="flip-up"
                                        data-aos-duration="2500"
                                    >and technologies used in the field.</div>
                                </div>
                            </div>
                            <div className='emailSocialMediaContainer'>
                                <div className='emailContainer'>
                                    <div>
                                        <p>josephaawad@gmail.com</p>
                                    </div>
                                </div>
                                <SocialMedia />
                            </div>

                        </div>
                    </Col>

                    <Col lg={6} md={12}>
                        <ProfilePicture />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutMe