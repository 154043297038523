import React, { useEffect, useState } from 'react';
import AnimatedCursor from 'react-animated-cursor';
import './App.scss'
import AboutMe from './sections/aboutme';
import HomePage from './sections/homepage';
import Loading from './sections/loading';
import Projects from './sections/projects';
import Services from './sections/services';
import Skills from './sections/skills';
import ContactUs from './sections/contactus';
import Footer from './sections/footer';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setInterval(time, 2750);
  }, []);
  const time = () => {
    setLoading(false)
  }
  return (
    <>
      <AnimatedCursor
        innerSize={10}
        outerSize={0}
        color='255, 255, 255'
        clickables={[
          'p',
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
      />
      {
        loading ?
          <Loading />
          :
          <>
            <HomePage />
            <AboutMe />
            <Services />
            <Projects />
            <Skills />
            <ContactUs />
            <Footer />
          </>
      }
    </>
  );
}

export default App;