import icon1 from '../../assets/images/icon1.png'

const services = [
    {
        id: 1,
        icon: icon1,
        title: 'Web Development',
        description: 'I have expertise in HTML, CSS, JavaScript, and other web technologies . I am confident that I can provide the best possible solutions for any web development project.'
    },
]

export default services