import React from 'react'
import './styles.scss'
import Title from '../../components/title'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import { BiRightArrow } from 'react-icons/bi'
import ProjectsList from './components/projectList'
function Projects() {
    const [showProjects, SetShowProjects] = useState(false)

    return (

        <div className='projectsWrapper' id="projects">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Title title="Experience & Showcase" />
                    </Col>
                </Row>
                <Row>
                    <div className='projectsBreifContainer'>
                        <div className='projectBreif'>
                            <span className='projectBreifNumber'>20+</span>
                            <span className='projectBreifText'>Small Projects</span>
                        </div>
                        <div className='projectBreif'>
                            <span className='projectBreifNumber'>1.5+</span>
                            <span className='projectBreifText'>Years Of Experience</span>
                        </div>
                        <div className='projectBreif'>
                            <span className='projectBreifNumber'>10+</span>
                            <span className='projectBreifText'>Development Skills</span>
                        </div>
                    </div>
                </Row>
                <Row>
                    {
                        !showProjects ?
                            <div className='showProjectsButtonContainer'>
                                <Button className='showProjectsButton' onClick={() => SetShowProjects(true)}>
                                    Show Projects <BiRightArrow color="fff" size={20} />
                                </Button>
                            </div>
                            :
                            <>
                                <ProjectsList SetShowProjects={SetShowProjects} />
                            </>
                    }
                </Row>
            </Container>
        </div >

    )
}

export default Projects