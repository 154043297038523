import React from 'react'
import './styles.scss'
import services from './servicesData'

function ServiceCards() {
    return (
        <>
            {
                services.map((card) => (
                    <div className='serviceCardWrapper' data-aos="zoom-in" data-aos-duration="1500" key={card.id}>
                        <div className='serviceCardImageContainer'>
                            <img width={100} height={100} src={card.icon} alt={card.name} />
                        </div>
                        <div className='serviceCardTitleContainer'>
                            <h2>{card.title}</h2>
                        </div>
                        <div className='featuresCardDescriptionContainer'>
                            <p>
                                {card.description}
                            </p>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default ServiceCards